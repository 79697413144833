import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ApplicationInsightsService } from '../../../services/application-insights.service';
import { IEvaluationData } from '../../../interfaces/evaluation-data';
import { EvaluationDataService } from '../../../services/evaluation-data.service';

@Component({
  selector: 'already-finished',
  templateUrl: './already-finished.component.html',
  encapsulation: ViewEncapsulation.None
})
export class AlreadyFinishedComponent implements OnInit {
  get evaluationData(): IEvaluationData {
    return this.evaluationDataService.evaluationData.value;
  }
  constructor(
    private evaluationDataService: EvaluationDataService,
    private applicationInsights: ApplicationInsightsService
  ) {}

  ngOnInit() {
    if (this.evaluationData && this.evaluationData.EvaluationId) {
      this.applicationInsights.trackEvent('EvaluationAlreadyFinished', {
        evaluationId: this.evaluationData.EvaluationId
      });
    }
  }
}
