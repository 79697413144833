import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { UntypedFormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import { IEvaluationQuestion } from '../interfaces/evaluation-question';
import { IEvaluationData } from '../interfaces/evaluation-data';
import { HttpClient } from '@angular/common/http';
import { IEvaluationQuestionAnswer } from '../interfaces/evaluation-question-answer';
import { ServiceNames } from '../interfaces/my7n-env-config';
import { AppConfig } from '../app-config.service';

@Injectable()
export class EvaluationDataService {
  /**
   * Maximum length of question feedback.
   * @type {number}
   */
  static readonly FEEDBACK_MAX_LENGTH = 500;

  /**
   * Evaluation API prefix.
   * @type {string}
   */
  readonly EVALUATION_API_PREFIX: string;

  /**
   * Stores information about currently displayed evaluation step.
   * @type {BehaviorSubject<number>}
   */
  currentStep = new BehaviorSubject<number>(0);

  /**
   * Stores information about evaluation.
   * @type {BehaviorSubject<IEvaluationData>}
   */
  evaluationData: BehaviorSubject<IEvaluationData> = new BehaviorSubject<IEvaluationData>(null);

  /**
   * Base form element for evaluation.
   * Every evaluation step has its own FormGroup instance inside this object.
   * @type {FormArray[]}
   */
  stepFormArray: UntypedFormArray;

  /**
   * Returns survey data from evaluationData observable.
   * @returns {Array<IEvaluationQuestion> | undefined}
   */
  get surveyData() {
    return this.evaluationData.value ? this.evaluationData.value.SurveyData : [];
  }

  constructor(private formBuilder: UntypedFormBuilder,
              private http: HttpClient,
              private evaluationConfig: AppConfig) {
    this.EVALUATION_API_PREFIX = evaluationConfig.serviceUrl(ServiceNames.Evaluation, 'v1') + '/evaluations';
  }

  /**
   * Creates reactive form for evaluation process.
   * Creates separate FormGroup object for each evaluation question.
   * Saves the FormArray instance in public property.
   */
  createForm() {
    const stepsCount = this.getStepsCount();
    this.stepFormArray = this.formBuilder.array([]);

    // create separate form group for each step
    for (let i = 0; i < stepsCount; i += 1) {
      const questionData = this.getQuestionData(i + 1);
      this.stepFormArray.push(this.formBuilder.group({
        questionId: [questionData.Id, Validators.required],
        evalAnswer: ['', Validators.required],
        feedback: ['', Validators.maxLength(EvaluationDataService.FEEDBACK_MAX_LENGTH)]
      }));
    }
  }

  /**
   * Returns number of survey steps.
   * @returns {number}
   */
  getStepsCount(): number {
    return this.surveyData.length;
  }

  /**
   * Checks if current step is the last one.
   * @returns {boolean}
   */
  isLastStep(): boolean {
    return this.currentStep.value === this.surveyData.length;
  }

  /**
   * Returns current step's question.
   * @returns {string}
   */
  getQuestion(): string {
    return this.surveyData[this.currentStep.value - 1].Question;
  }

  /**
   * Returns current step's desired skills.
   * @returns {string}
   */
  getDesiredSkills(): string[] {
    return this.surveyData[this.currentStep.value - 1] ? this.surveyData[this.currentStep.value - 1].Skills : [];
  }

  /**
   * Returns current step's title.
   * @returns {string}
   */
  getTitle(): string {
    return this.surveyData[this.currentStep.value - 1] ? this.surveyData[this.currentStep.value - 1].Title : '';
  }

  /**
   * Returns details about specified evaluation.
   *
   * @param {string} evaluationId
   * @returns {IEvaluationData}
   */
  getEvaluationData(evaluationId: string): Promise<IEvaluationData> {
    return lastValueFrom(this.http.get<IEvaluationData>([this.EVALUATION_API_PREFIX, evaluationId.toString(), 'questionnaire'].join('/'))).then((data) => {
      this.evaluationData.next(data);
      return data;
    });
  }

  /**
   * Returns details about specified evaluation question.
   *
   * @param {number} questionNo
   * @returns {IEvaluationQuestion}
   */
  getQuestionData(questionNo: number): IEvaluationQuestion {
    return this.surveyData[questionNo - 1];
  }

  /**
   * Returns evaluation response data.
   * @returns {IEvaluationResult}
   */
  getSurveyResponse(): Array<IEvaluationQuestionAnswer> {

    const data = this.stepFormArray.controls.map((stepForm) => {
      return {
        QuestionId: stepForm.value.questionId,
        AnswerId: stepForm.value.evalAnswer,
        Feedback: stepForm.value.feedback
      };
    });

    return data;
  }

  /**
   * Closes evaluation.
   * @param {string} evaluationId
   */
  completeEvaluation(evaluationId: string): Promise<any> {
    if (this.stepFormArray.valid) {
      console.log('Evaluation completed');
      return lastValueFrom(this.http.put<Array<IEvaluationQuestionAnswer>>([this.EVALUATION_API_PREFIX, evaluationId.toString(), 'questionnaire'].join('/'), this.getSurveyResponse()));
    } else {
      console.error('Evaluation can\'t be completed. Please answer to all questions.' );
    }
  }
}
