<info-page>
  <ng-template #image><img src="/assets/images/evaluation/expired.png" alt="" /></ng-template>
  <ng-template #title>Evaluation time period has expired</ng-template>
  <ng-template #description>
    You had eight weeks to evaluate the consultant - four weeks before the end of the contract and four weeks
    after the contract termination. If you have any questions, please <a *ngIf="evaluationData?.ContactEmail; else noLink" href="mailto:{{evaluationData?.ContactEmail}}">contact your 7N Agent</a>.
  </ng-template>
  <ng-template #noLink>
    contact your 7N Agent
  </ng-template>
</info-page>
