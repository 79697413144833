import { Component, OnInit } from '@angular/core';
import { EvaluationDataService } from '../../services/evaluation-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { IEvaluationData } from '../../interfaces/evaluation-data';
import { ResolveDecorator } from '../../classes/resolve-decorator';
import { HttpErrorResponse } from '@angular/common/http';
import { ApplicationInsightsService } from '../../services/application-insights.service';
import { SeverityLevel } from '../../interfaces/my7n-env-config';

@Component({
  selector: 'evaluation-complete',
  templateUrl: './evaluation-complete.component.html',
  styleUrls: ['./evaluation-complete.component.scss']
})
export class EvaluationCompleteComponent implements OnInit {

  get evaluationData(): IEvaluationData {
    return this.evaluationDataService.evaluationData.value;
  }

  constructor(private evaluationDataService: EvaluationDataService,
              private router: Router,
              private route: ActivatedRoute,
              private applicationInsights: ApplicationInsightsService) { }

  ngOnInit() {
    const evaluationId = this.route.snapshot.params.evalId;

    if (!this.evaluationDataService.stepFormArray) {
      // if someone is trying to access evaluation/:evalId/complete directly
      // redirect to evaluation/:evalId/start
      return this.router.navigateByUrl(`/evaluation/${evaluationId}/start`);
    }

    // trying to complete evaluation
    this.evaluationDataService.completeEvaluation(evaluationId).then(() => {
      this.applicationInsights.trackEvent('EvaluationCompleted', { evaluationId: this.evaluationData.EvaluationId});
    })
    .catch((e: HttpErrorResponse) => {
      console.error('Completion error: ', e.message);
      ResolveDecorator.redirectToErrorPage(e, this.router);
      this.applicationInsights.trackException(e, { evaluationId: this.evaluationData.EvaluationId}, SeverityLevel.Error);
    });

    // reset currentStep value to clear title desc
    this.evaluationDataService.currentStep.next(0);
  }
}
