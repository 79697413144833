<info-page>
  <ng-template #image><img src="/assets/images/evaluation/already-sent.png" alt="" /></ng-template>
  <ng-template #title>You have already<br /> evaluated this consultant</ng-template>
  <ng-template #description>
    Thank you for your feedback. It is highly appreciated and will help us to continue
    delivering Top Class consultants in the future. If you have any comments
    or questions, please feel free to <a *ngIf="evaluationData?.Agent.Email; else noLink" href="mailto:{{evaluationData?.Agent.Email}}">contact your 7N Agent</a>.
  </ng-template>
  <ng-template #noLink>
    contact your 7N Agent
  </ng-template>
</info-page>
