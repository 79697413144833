type ServicesHashMap = {
  [serviceName in ServiceNames]: string;
};
export interface IMy7nEnvConfig {
  GOOGLE_API_KEY: string;
  APPLICATION_URL: string;
  EVALUATION_APPLICATION_URL: string;
  VERSION_HASH: string;
  LOG_VERBOSITY_LEVEL: SeverityLevel;
  INSIGHTS_KEY: string;
  INSIGHTS_VERBOSITY_LEVEL: SeverityLevel;
  INSIGHTS_INTERNAL_VERBOSITY: boolean;
  allowedUrls: Array<string>;
  services: Partial<ServicesHashMap>;
}

export enum ServiceNames {
  Core = 'Core',
  Evaluation = 'Evaluation'
}

export enum SeverityLevel {
  Verbose = 0,
  Information = 1,
  Warning = 2,
  Error = 3,
  Critical = 4
}
