import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import {map} from 'rxjs/operators';


import { MY7N_ENV_CONFIG } from '../functions/my7n-env-config';
import { IMy7nEnvConfig, ServiceNames } from '../interfaces/my7n-env-config';
import { SnackBarService, SnackBarTypes } from '@my7n/ui';

@Injectable()
export class CsrfHeaderInterceptorHttpInterceptor implements HttpInterceptor {
  constructor(@Inject(MY7N_ENV_CONFIG) private envConfig: IMy7nEnvConfig,
              private snackBarService: SnackBarService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestUrl = req.url,
      currentDate = new Date();
      if (requestUrl.startsWith(this.envConfig.services[ServiceNames.Core])) {
        req = req.clone({
          setHeaders: {
            'csrf-token': btoa(currentDate.getUTCFullYear() + 'ILove7N')
          }
        });
      }

    return next.handle(req).pipe(map(
      (event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          const token = event.headers.get('csrf-token'),
            expected = btoa(currentDate.getUTCFullYear() + 'ILove7NToo');

          if (requestUrl.indexOf('api/') === 0 && (!token || (token && token !== expected))) {
            console.error('[CsrfHeaderInterceptorHttpInterceptor] Server response missing proper token, check local network settings or contact administrator');

            setTimeout(() => {
              this.snackBarService.open({
                type: SnackBarTypes.ErrorAlt,
                message: 'Your network intercepts server responses, please contact your network administrator to solve the problem'
              });
            }, 1000);
          }
        }

        return event;
      }));
  }
}
