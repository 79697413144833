import { Inject, Injectable } from '@angular/core';
import { MY7N_ENV_CONFIG } from './functions/my7n-env-config';
import { IMy7nEnvConfig } from './interfaces/my7n-env-config';
import { ServiceNames } from './interfaces/my7n-env-config';

@Injectable()
export class AppConfig {

  constructor(@Inject(MY7N_ENV_CONFIG) private envConfig: IMy7nEnvConfig) {}

  serviceUrl(serviceName: ServiceNames = ServiceNames.Core, version: string = 'v2'): string {
    if (!this.envConfig.services[serviceName]) {
      console.error(`[AppConfig] service ${serviceName} is missing in configuration, empty string returned`);
      return '';
    }
    return `${this.envConfig.services[serviceName].replace(/\/?$/, '/')}${version}`; // Add trailing slash if it's missing
  }
}
