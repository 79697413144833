import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { EvaluationStepComponent } from './components/evaluation-step/evaluation-step.component';
import { EvaluationCompleteComponent } from './components/evaluation-complete/evaluation-complete.component';
import { EvaluationStartComponent } from './components/evaluation-start/evaluation-start.component';
import { EvaluationDataResolver } from './resolvers/evaluation-data.resolver';
import { ExpiredComponent } from './components/errors/expired/expired.component';
import { AlreadyFinishedComponent } from './components/errors/already-finished/already-finished.component';
import { ServerErrorComponent, NotFoundErrorComponent } from '@my7n/ui';

const subRoutes: Routes = [
  {
    path: ':evalId/complete',
    component: EvaluationCompleteComponent
  },
  {
    path: ':evalId/start',
    component: EvaluationStartComponent
  },
  {
    path: ':evalId/step/:stepNo',
    component: EvaluationStepComponent
  }
];

const routes: Routes = [
  {
    path: 'evaluation/not-found',
    component: NotFoundErrorComponent,
    data: {
      hideDashboardLink: true
    }
  },
  {
    path: 'evaluation/server-error',
    component: ServerErrorComponent,
    data: {
      hideDashboardLink: true
    }
  },
  {
    path: 'evaluation/expired',
    component: ExpiredComponent
  },
  {
    path: 'evaluation/already-finished',
    component: AlreadyFinishedComponent
  },
  {
    path: 'evaluation',
    children: subRoutes,
    resolve: {
      evaluationData: EvaluationDataResolver
    }
  },
  {
    path: '**',
    component: NotFoundErrorComponent,
    data: {
      hideDashboardLink: true
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false,
    onSameUrlNavigation: 'reload',
    enableTracing: false
})],
  exports: [RouterModule],
  providers: [EvaluationDataResolver]
})
export class My7nEvaluationRoutingModule { }
