<nav [ngClass]="{'hidden': hideNavbar}">
  <mat-toolbar>
    <mat-toolbar-row class="mat-toolbar-row-padding flex flex-start-stretch">
      <div class="navbar-logo hidden sm:flex flex-start-center">
        <img src="assets/images/logo-my7n.svg" alt="my7N" />
      </div>
      <div class="consultant-name sm:hidden">Evaluation of {{evaluationData?.ConsultantName}}</div>
    </mat-toolbar-row>
  </mat-toolbar>
</nav>
<div class="main-container-wrapper">
  <main id="main-container">
    <div class="view-loader full-screen flex flex-col flex-auto flex-center-center"
         *ngIf="loading">
      <loader color="primary"></loader>
    </div>
    <div class="main-view" [ngClass]="{loading: loading}" [@routerOutletVisibility]="loading ? 'hidden': 'shown'">
      <router-outlet></router-outlet>
    </div>
  </main>
</div>

<div class="chat-bot-container">
  <chat-bot-button [disabled]="false" [message]="chatBotMessage" [title]="evaluationData?.Agent?.Name">
    <avatar [avatarUrl]="evaluationData?.Agent?.Avatar" [title]="evaluationData?.Agent?.Name" [plugEnabled]="false"></avatar>
  </chat-bot-button>
</div>
