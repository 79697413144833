import { Component, OnInit } from '@angular/core';
import { EvaluationDataService } from '../../services/evaluation-data.service';
import { Router } from '@angular/router';
import { IEvaluationData } from '../../interfaces/evaluation-data';
import { ApplicationInsightsService } from '../../services/application-insights.service';

@Component({
  selector: 'evaluation-start',
  templateUrl: './evaluation-start.component.html',
  styleUrls: ['./evaluation-start.component.scss']
})
export class EvaluationStartComponent implements OnInit {
  get evaluationData(): IEvaluationData {
    return this.evaluationDataService.evaluationData.value;
  }

  constructor(
    private evaluationDataService: EvaluationDataService,
    private router: Router,
    private applicationInsights: ApplicationInsightsService
  ) {}

  ngOnInit() {
    // if evaluation has been already completed redirect to proper view
    if (this.evaluationData.IsCompleted === true) {
      this.router.navigateByUrl('/evaluation/already-finished');
    } else {
      // reset currentStep value to clear title desc
      this.evaluationDataService.currentStep.next(0);
    }
  }

  startEvaluation() {
    this.applicationInsights.trackEvent('EvaluationStarted', {
      evaluationId: this.evaluationData.EvaluationId
    });
    this.router.navigate([
      'evaluation',
      this.evaluationData.EvaluationId,
      'step',
      1
    ]);
  }
}
