import {Component, Host, OnInit, Optional, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {EvaluationDataService} from '../../services/evaluation-data.service';
import { UntypedFormArray, UntypedFormGroup, NgForm } from '@angular/forms';
import {IEvaluationData} from '../../interfaces/evaluation-data';
import {StepperNavigationComponent, StepperNavigationService, SubNavbarService, FormSubmitIndicatorDirective} from '@my7n/ui';

@Component({
  selector: 'evaluation-step',
  templateUrl: './evaluation-step.component.html',
  styleUrls: ['./evaluation-step.component.scss'],
  providers: [StepperNavigationService]
})
export class EvaluationStepComponent implements OnInit {
  /**
   * Step parameter name using in routes.
   * @type {string}
   * @static
   */
  static readonly STEP_PARAM_NAME = 'stepNo';

  /**
   * Number of survey steps.
   * @type {number}
   */
  stepsCount: number;

  /**
   * Reference to the FormGroup for current evaluation step.
   * It is updated whenever user navigates between survey questions.
   */
  stepForm: UntypedFormGroup;

  /**
   * Flag indicates if user tried to submit current step form.
   */
  formSubmitAttempt: boolean;

  /**
   * Reference to the FormSubmitIndicator directive used in HTMLFormElement.
   */
  @ViewChild(FormSubmitIndicatorDirective, { static: true }) formSubmitIndicatorDirective: FormSubmitIndicatorDirective;

  /**
   * Reference to the question form.
   */
  @ViewChild('questionForm') questionForm: NgForm;

  /**
   * Evaluation details.
   */
  get evaluationData(): IEvaluationData {
    return this.evaluationDataService.evaluationData.value;
  }

  /**
   * Returns main form object for evaluation survey.
   * @returns {FormArray}
   */
  get stepFormArray(): UntypedFormArray {
    return this.evaluationDataService.stepFormArray;
  }

  /**
   * Returns current step value.
   * @returns {number}
   */
  get currentStep(): number {
    return this.evaluationDataService.currentStep.value;
  }

  /**
   * Returns current step's title.
   * @returns {number}
   */
  get title(): string {
    return this.evaluationDataService.getTitle();
  }

  /**
   * Returns current step's question.
   * @returns {string}
   */
  get question(): string {
    return this.evaluationDataService.getQuestion();
  }

  /**
   * Returns current step's desired skills.
   * @returns {string[]}
   */
  get desiredSkills(): string[] {
    return this.evaluationDataService.getDesiredSkills();
  }

  /**
   * Returns maximum length of user feedback.
   * @returns {number}
   */
  get feedbackMaxLength(): number {
    return EvaluationDataService.FEEDBACK_MAX_LENGTH;
  }

  constructor(private route: ActivatedRoute, private router: Router,
              private evaluationDataService: EvaluationDataService,
              private subNavbarService: SubNavbarService,
              @Host()
              @Optional()
              public stepperNavigationService: StepperNavigationService) {
    this.stepsCount = evaluationDataService.getStepsCount();
    const stepParam = EvaluationStepComponent.STEP_PARAM_NAME;

    /**
     * Set step number passed in route as current one.
     */
    route.params.subscribe( (params) => {
      if (params[stepParam]
          && params[stepParam] > 0
          && params[stepParam] <= this.stepsCount) {
        this.evaluationDataService.currentStep.next(+params[stepParam]);
      }
    });
  }

  ngOnInit() {
    if (this.evaluationData.IsCompleted === true) {
      this.router.navigateByUrl('/evaluation/already-finished');
      this.evaluationDataService.currentStep.next(0);
    } else {
      this.stepperNavigationService.updateMaxStep(this.stepsCount);
      this.stepperNavigationService.updateTitle('Evaluation of ' + this.evaluationData.ConsultantName);
      this.subNavbarService.updateSecondLevelComponent(StepperNavigationComponent);

      this.createForm();

      /**
       * Listening on step forward request action that comes from  sub-navbar's button.
       * If user requests step forward form is validated and form is valid:
       * - form group is changed (to point on next question in evaluation survey)
       * - form status is reset ( pristine, untouched, clear submit state )
       * - user is navigated to /evaluation/:evalId/step/:stepId
       */
      this.stepperNavigationService.stepForwardRequested$.subscribe((isRequested) => {
        if (isRequested === true) {
          // ngSubmit event is mandatory for displaying validation messages
          this.questionForm.ngSubmit.emit();
        }
      });

      this.stepperNavigationService.stepBackRequested$.subscribe((isRequested) => {
        if (isRequested === true) {
          this.prevStep();
        }
      });

      this.stepperNavigationService.completeSteps$.subscribe((completeRequest) => {
        if (completeRequest === true) {
          // ngSubmit event is mandatory for displaying validation messages
          this.questionForm.ngSubmit.emit();
        }
      });
    }
  }

  /**
   * Triggers creation of evaluation form.
   * Subscribes listener for currentStep change.
   */
  createForm() {
    // form is created in service cause its state have to be accessible from different routes.
    this.evaluationDataService.createForm();

    this.evaluationDataService.currentStep.subscribe(data => {
      // set proper FormGroup when current step has been changed.
      // it is useful in case when user changed URL and started evaluation from step later than first.
      this.stepForm = this.getStepFormGroup(this.currentStep);

      // update current step on stepper component
      this.stepperNavigationService.updateCurrentStep(this.currentStep);

      // submit flag is reset when user changes question form
      this.formSubmitAttempt = false;
    });
  }

  /**
   * Handles submit of evaluation step form.
   */
  onSubmit() {
    this.formSubmitAttempt = true;
    this.stepForm.markAllAsTouched();

    if (this.stepForm.valid) {
      this.nextStep();
    } else {
      this.stepForm.markAsTouched();
      this.stepperNavigationService.updateStepForwardRequested(false);
      this.stepperNavigationService.updateCompleteSteps(false);
      console.warn('Invalid data');
    }
  }

  /**
   * Makes step ahead.
   * If there's no other question it redirects to complete page.
   */
  nextStep() {
    if (!this.evaluationDataService.isLastStep()) {

      // bind stepForm to next question's FormGroup
      this.stepForm = this.getStepFormGroup(this.currentStep + 1);

      this.stepForm.markAsPristine();
      this.stepForm.markAsUntouched();
      this.formSubmitIndicatorDirective.clearSubmitState();

      this.router.navigateByUrl(`/evaluation/${this.evaluationData.EvaluationId}/step/${this.currentStep + 1}`);
    } else {
      if (!this.evaluationDataService.stepFormArray.valid) {
        // redirect to first page with missing answer
        const questionIndex = this.evaluationDataService.stepFormArray.value.findIndex((el) => el.evalAnswer === '');
        this.router.navigateByUrl(`/evaluation/${this.evaluationData.EvaluationId}/step/${questionIndex + 1}`);
      } else {
        // all questions got answers, redirecting to complete page.
        this.router.navigateByUrl(`/evaluation/${this.evaluationData.EvaluationId}/complete`);
      }
    }
  }

  /**
   * Makes step back.
   */
  prevStep() {
    if (this.currentStep > 1) {
      // bind stepForm to previous question's FormGroup
      this.stepForm = this.getStepFormGroup(this.currentStep - 1);
      this.formSubmitIndicatorDirective.clearSubmitState();
      this.router.navigateByUrl(`/evaluation/${this.evaluationData.EvaluationId}/step/${this.currentStep - 1}`);
    }
  }

  /**
   * Returns FormGroup for specified step.
   * @param {number} stepNo Number of evaluation survey step.
   * @returns {FormGroup}
   */
  getStepFormGroup(stepNo: number): UntypedFormGroup {
    return this.evaluationDataService.stepFormArray.controls[stepNo - 1] as UntypedFormGroup;
  }
}
