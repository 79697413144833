import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, OnInit } from '@angular/core';
import { IconsService, SubNavbarService } from '@my7n/ui';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { delay } from 'rxjs/operators';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from '@angular/router';

import { EvaluationDataService } from '../../services/evaluation-data.service';
import { IEvaluationData } from '../../interfaces/evaluation-data';

const ExtendedNavbarConfig = {
  expandTiming: '.5s ease-in',
  collapseTiming: '.2s ease-out'
};

@Component({
  selector: 'body',
  templateUrl: './evaluation-main.component.html',
  styleUrls: ['./evaluation-main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('routerOutletVisibility', [
      state('shown', style({ opacity: '1' })),
      state('hidden', style({ opacity: '0' })),
      state('void', style({ opacity: '0' })),
      transition('hidden => shown', animate('.5s .2s ease-in')),
      transition('shown => hidden', animate(ExtendedNavbarConfig.collapseTiming))
    ])
  ]
})
export class EvaluationMainComponent implements OnInit {
  @HostBinding('class.second-lvl-nav-visible') subnavbarCssClass = false;

  hideNavbar = true;

  /**
   * Page loader flag.
   */
  loading = true;

  get evaluationData(): IEvaluationData {
     return this.evaluationDataService.evaluationData.value;
  }

  private defaultChatBotMessage = `Our primary focus is to continue to deliver the highest possible quality and to be
   able to do that, your feedback is vital to us. Please feel free to contact me if you need any further information.<br /><br /> Best regards,<br />`;

  get chatBotMessage() {
    const evalData = this.evaluationData;
    let message = this.defaultChatBotMessage;
    if (evalData) {
      message += evalData.Agent.Name;
    }
    return message;
  }

  constructor(private evaluationDataService: EvaluationDataService,
              private changeDetectionRef: ChangeDetectorRef,
              private iconsService: IconsService,
              private router: Router,
              private subNavBarService: SubNavbarService) {
  }

  ngOnInit() {
    this.iconsService.generateCustomMaterialIcons();
    this.evaluationDataService.currentStep.subscribe((step) => {
      this.hideNavbar = step <= 0;
    });

    // Subscribe to route change to set the view class
    this.router.events.subscribe((val) => {
        switch (val.constructor) {
          case NavigationStart:
            this.loader(true);
            break;

          case NavigationEnd:
          case NavigationCancel:
          case NavigationError:
            this.loader(false);
            break;
        }
      }
    );

    this.subNavBarService.isSecondLevelNavVisible$.pipe(
        delay(0)
    ).subscribe((visible) => {
      this.subnavbarCssClass = visible;
    });
  }

  private loader(value: boolean) {
    this.loading = value;
    this.changeDetectionRef.detectChanges();
  }
}
