
// vendor modules
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { My7nUiModule } from '@my7n/ui';

// MODULES
import { My7nEvaluationRoutingModule } from './my7n-evaluation-routing.module';
import { PortalModule } from '@angular/cdk/portal';
import { EvaluationMaterialModule } from './modules/evaluation-material.module';

// COMPONENTS
import { EvaluationMainComponent } from './components/evaluation-main/evaluation-main.component';
import { EvaluationStartComponent } from './components/evaluation-start/evaluation-start.component';
import { EvaluationStepComponent } from './components/evaluation-step/evaluation-step.component';
import { EvaluationCompleteComponent } from './components/evaluation-complete/evaluation-complete.component';
import { ExpiredComponent } from './components/errors/expired/expired.component';
import { AlreadyFinishedComponent } from './components/errors/already-finished/already-finished.component';

// SERVICES
import { EvaluationDataService } from './services/evaluation-data.service';
import { ApplicationInsightsService } from './services/application-insights.service';
// INTERCEPTORS
import { CsrfHeaderInterceptorHttpInterceptor } from './interceptors/csrf-header-http-interceptor.service';

// init functions
import { MY7N_ENV_CONFIG, my7nEnvConfigFactory } from './functions/my7n-env-config';
import { AppConfig } from './app-config.service';
import { initAll } from './my7n-evaluation-init';

@NgModule({ declarations: [
        EvaluationMainComponent,
        EvaluationStartComponent,
        EvaluationStepComponent,
        EvaluationCompleteComponent,
        ExpiredComponent,
        AlreadyFinishedComponent
    ],
    bootstrap: [EvaluationMainComponent], imports: [BrowserModule,
        CommonModule,
        BrowserAnimationsModule,
        FormsModule,
        My7nEvaluationRoutingModule,
        EvaluationMaterialModule,
        ReactiveFormsModule,
        My7nUiModule,
        PortalModule], providers: [
        EvaluationDataService,
        AppConfig,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CsrfHeaderInterceptorHttpInterceptor,
            multi: true
        },
        {
            provide: MY7N_ENV_CONFIG,
            useFactory: my7nEnvConfigFactory
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initAll,
            deps: [ApplicationInsightsService],
            multi: true
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class My7nEvaluationModule { }
